import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { InPageNavigationWorkshops } from "../components/inpage-navigation"

const Workshops = () => (
    <Layout>
        <Seo title="FEARS Workshops" />
        <InPageNavigationWorkshops />
        <div className="fluid-container">
            <div className="row">
                <article id="portal-column-content" className="cell col-sm-9 mbottom-default">
                    <h1>Doctoral Schools Workshops</h1>

                    <h2 id="poster">How to develop an academic poster?</h2>

                    <div className="row padding-0">
                        <div className="col-sm-12">
                            <a href="https://wurtl.ugent.be/c/1/fears2024" className="btn btn-default"
                               activeClassName="active">Register for FEARS (and select this workshop)</a>
                        </div>
                    </div>

                    <div className="row padding-0">
                        <div className="col-sm-6">
                            <h3>Target group</h3>
                            <p>PhD students or other researchers. Aimed at beginners who are in their first two years,
                                but more experienced researchers can join as well.</p>
                        </div>
                        <div className="col-sm-2">
                            <h3>Participant limit</h3>
                            <p>25 places.</p>
                        </div>
                        <div className="col-sm-2">
                            <h3>Date</h3>
                            <p>Monday September 30th, 13:30-16:30</p>
                        </div>
                        <div className="col-sm-2">
                            <h3>Location</h3>
                            <p>Sint-Pietersnieuwstraat 136, 9000 Gent</p>
                        </div>
                    </div>

                    <div className="row padding-0">
                        <div className="alert alert-warning"><strong>INFO </strong> This workshop is held before FEARS,
                            on September 30th!
                        </div>
                    </div>

                    <div className="row pbottom-default">
                        <div className="col-sm-8">
                            <h3>Workshop content</h3>
                            <p>
                                The poster is a great conference tool that presents the main topic and / or results of
                                your study to introduce the research to a broader academic network. This workshop gives
                                an insight into the development process of a clear, attractive and informative academic
                                poster. The attendees will be presented with discussion topics and authentic examples to
                                instigate an interactive brainstorm, thus gaining awareness of do's and don'ts of the
                                poster medium and providing tips and tricks for most effectively communicating their
                                main message.
                            </p>
                            <p>
                                For more than ten years already, Tom De Moor has been providing a broad range of
                                academic English training sessions across all UGent faculties. One of his domains of
                                specialization is the academic poster. He has co-developed and frequently teaches the DS
                                transferable skills course on the topic and keeps a finger on the pulse of trends and
                                evolutions of the medium.
                            </p>
                        </div>
                        <div className="col-sm-4">
                            <h3>Doctoral School credits</h3>
                            <p>
                                The combination of following this workshop and an active participation at FEARS with a
                                poster presentation will grant you doctoral school credits.
                                It is not possible to receive credits for multiple workshops at FEARS.
                            </p>
                        </div>
                    </div>


                    <div className="pbottom-default" style={{ borderTop: "1px solid #ccc" }}></div>
                    <h2 id="pitch">How to pitch your research for a broader audience?</h2>
                    <div className="row pbottom-default">
                        <div className="col-sm-12">
                            <a href="https://wurtl.ugent.be/c/1/fears2024" className="btn btn-default"
                               activeClassName="active">Register for FEARS (and select this workshop)</a>
                        </div>
                    </div>
                    <div className="row padding-0">
                        <div className="col-sm-6">
                            <h3>Target group</h3>
                            <p>PhD students or other researchers. Aimed at beginners who are in their first two years,
                                but more experienced researchers can join as well.</p>
                        </div>
                        <div className="col-sm-2">
                            <h3>Participant limit</h3>
                            <p>25 places.</p>
                        </div>
                        <div className="col-sm-2">
                            <h3>Date</h3>
                            <p>Thursday October 3rd, 13:30-16:30</p>
                        </div>
                        <div className="col-sm-2">
                            <h3>Location</h3>
                            <p>Sint-Pietersnieuwstraat 136, 9000 Gent</p>
                        </div>
                    </div>
                    <div className="row padding-0">

                    </div>
                    <div className="row padding-0">
                        <div className="alert alert-warning"><strong>INFO </strong> This workshop is held before FEARS,
                            on October 3rd!
                        </div>
                    </div>
                    <div className="row pbottom-default">
                        <div className="col-sm-8">
                            <h3>Workshop content</h3>
                            <p>Pitching is the technique of communicating an idea or project in a way that enables the
                                audience to share the presenter's enthusiasm. An effective pitch bridges the
                                communicative gap between the presenter's expertise and the audience's potential
                                unfamiliarity with the subject. This workshop expands attendees' toolset of best
                                practices in bridging that gap and presenting their projects in general, and challenges
                                them to critique and improve authentic pitches, including each other's and their
                                own.</p>

                            <p>Nils Smeuninx has been an English-language communication and speaking skills instructor
                                for over ten years. He wrote his doctoral thesis on the readability, understandability
                                and communicative techniques of corporate communications, and continues to apply that
                                expertise throughout the various language proficiency and Doctoral Schools transferable
                                skills courses he teaches at UGent's University Language Centre.</p>
                        </div>
                        <div className="col-sm-4">
                            <h3>Doctoral School credits</h3>
                            <p>
                                The combination of following this workshop and an active participation at FEARS by
                                giving a pitch will grant you doctoral school credits.
                                It is not possible to receive credits for multiple FEARS-workshops.
                            </p>
                        </div>
                    </div>


                    <div className="pbottom-default" style={{ borderTop: "1px solid #ccc" }}></div>
                    <h2 id="questions">Questions: how to ask them and how to answer them in a clear, concise and
                        confident way.</h2>
                    <div className="row pbottom-default">
                        <div className="col-sm-12">
                            <a href="https://wurtl.ugent.be/c/1/fears2024" className="btn btn-default"
                               activeClassName="active">Register for FEARS (and select this workshop)</a>
                        </div>
                    </div>
                    <div className="row padding-0">
                        <div className="col-sm-6">
                            <h3>Target group</h3>
                            <p>PhD students or other researchers joining the industry roundtables at FEARS, but anyone
                                else is welcome to join as well.</p>
                        </div>
                        <div className="col-sm-2">
                            <h3>Participant limit</h3>
                            <p>30 places.</p>
                        </div>
                        <div className="col-sm-2">
                            <h3>Date</h3>
                            <p>Thursday October 17th, 13:30-16:30</p>
                        </div>
                        <div className="col-sm-2">
                            <h3>Location</h3>
                            <p>S8, Campus De Sterre, Gent</p>
                        </div>
                    </div>
                    <div className="row padding-0">

                    </div>
                    <div className="row padding-0">
                        <div className="alert alert-warning"><strong>INFO </strong> This workshop is held before FEARS,
                            on October 17th!
                        </div>
                    </div>
                    <div className="row pbottom-default">
                        <div className="col-sm-8">
                            <h3>Workshop content</h3>
                            <p>Asking and answering questions is a key competency, not just in research but in the wider
                                professional arena. In this worksop you will learn how to ask and answer questions in
                                the specific context of a roundtable with industry representatives. The skill-set that
                                we'll focus on includes taking and holding the floor, hedging claims, agreeing and
                                disagreeing, checking understanding and listening actively, bridging the gap between
                                academia and business. The workshop features breakout sessions in which you will get the
                                opportunity to practice and receive personalized feedback.</p>

                            {/* <p>Nils Smeuninx has been an English-language communication and speaking skills instructor for over ten years. He wrote his doctoral thesis on the readability, understandability and communicative techniques of corporate communications, and continues to apply that expertise throughout the various language proficiency and Doctoral Schools transferable skills courses he teaches at UGent's University Language Centre.</p> */}
                        </div>
                        <div className="col-sm-4">
                            <h3>Doctoral School credits</h3>
                            <p>
                                The combination of following this workshop and an active participation at FEARS during
                                the <Link to="/roundtables/">roundtable discussions</Link>, will grant you doctoral
                                school credits.
                                Participants of this workshop will get priority in the selection of the roundtable topic
                                of their choice.
                                It is not possible to receive credits for multiple FEARS-workshops.
                            </p>
                        </div>
                    </div>

                    <h1>FEARS Workshops</h1>

                    <h2 id="cv">Your PhD in a CV</h2>

                    <div className="row padding-0">
                        <div className="col-sm-12">
                            <a href="https://wurtl.ugent.be/c/1/fears2024" className="btn btn-default"
                               activeClassName="active">Register for FEARS (and select this workshop)</a>
                        </div>
                    </div>

                    <div className="row padding-0">
                        <div className="col-sm-6">
                            <h3>Target group</h3>
                            <p>Finalising PhD students and postdocs. Aimed at more experienced researchers, but
                                beginners who are in their first two years can join as well.</p>
                        </div>
                        <div className="col-sm-2">
                            <h3>Participant limit</h3>
                            <p>30 places.</p>
                        </div>
                        <div className="col-sm-4">
                            <h3>Date</h3>
                            <p>Wednesday November 27th (FEARS), 13:30-14:30</p>
                        </div>
                    </div>

                    <div className="row pbottom-default">
                        <div className="col-sm-8">
                            <h3>Workshop content</h3>
                            <p>
                                In this interactive workshop we will list do’s and don’ts for drafting a cv with an
                                academic background.
                            </p>
                            <p>
                                Your cv is the business card that makes (or breaks) the first impression a recruiter
                                gets of your profile. It should show your professional personality, be adapted to the
                                targeted position and communicate your killer points. In this workshop, we will deduce
                                guidelines regarding style, structure and content based on discussion of frequently
                                raised questions and authentic input.
                            </p>
                            <p>
                                Tom De Moor works for the University Language Centre of Ghent University (<a
                                target="_blank" rel="noopener noreferrer" href="https://uct.ugent.be/en">UCT</a>), where
                                he coordinates, develops and teaches courses in academic and professional language
                                education. Among other transferable skills, he frequently gives workshops and coaches on
                                academic application skills.
                            </p>
                        </div>
                        <div className="col-sm-4">
                            <h3>Location</h3>
                            <p>Lady's mantle, Sint-Pietersabdij<br></br>Sint-Pietersplein 9, 9000 Gent</p>
                        </div>
                    </div>

                    <div className="pbottom-default" style={{ borderTop: "1px solid #ccc" }}></div>
                    <h2 id="valorization">Funding for entrepreneurial postdocs</h2>

                    <div className="row padding-0">
                        <div className="col-sm-12">
                            <a href="https://wurtl.ugent.be/c/1/fears2024" className="btn btn-default"
                               activeClassName="active">Register for FEARS (and select this workshop)</a>
                        </div>
                    </div>

                    <div className="row padding-0">
                        <div className="col-sm-6">
                            <h3>Target group</h3>
                            <p>Finalising PhD students and postdocs. Aimed at more experienced researchers, but
                                beginners who are in their first two years can join as well.</p>
                        </div>
                        <div className="col-sm-2">
                            <h3>Participant limit</h3>
                            <p>30 places.</p>
                        </div>
                        <div className="col-sm-4">
                            <h3>Date</h3>
                            <p>Wednesday November 27th (FEARS), 15:30-16:30</p>
                        </div>
                    </div>

                    <div className="row pbottom-default">
                        <div className="col-sm-8">
                            <h3>Workshop content</h3>
                            <p>
                                After spending years on research, postdocs might like to do something with the results
                                they obtained. In this presentation several aspects are discussed like protection
                                (confidentiality, patents) and funding opportunities for valorization.
                            </p>
                            <p>
                                Different programs (EU, VLAIO, IOF) for postdocs will be explained such as programs with
                                the aim to make prototypes for different stakeholders, to collaborate with specific
                                companies or to create an own spin-off company.
                            </p>
                            <p>
                                Information about dedicated courses and services given by UGENT TECHTRANSFER will be
                                provided.
                            </p>
                        </div>
                        <div className="col-sm-4">
                            <h3>Location</h3>
                            <p>Lady's mantle, Sint-Pietersabdij<br></br>Sint-Pietersplein 9, 9000 Gent</p>
                        </div>
                    </div>

                    <div className="pbottom-default" style={{ borderTop: "1px solid #ccc" }}></div>
                    <h2 id="starting-phd">Pursuing a doctoral degree</h2>

                    <div className="row padding-0">
                        <div className="col-sm-12">
                            <a href="https://wurtl.ugent.be/c/1/fears2024" className="btn btn-default"
                               activeClassName="active">Register for FEARS (and select this workshop)</a>
                        </div>
                    </div>

                    <div className="row padding-0">
                        <div className="col-sm-6">
                            <h3>Target group</h3>
                            <p>Students. Aimed at master students who want to figure out whether a PhD is something for
                                them, but other students can join as well.</p>
                        </div>
                        <div className="col-sm-2">
                            <h3>Participant limit</h3>
                            <p>40 places.</p>
                        </div>
                        <div className="col-sm-4">
                            <h3>Date</h3>
                            <p>Wednesday November 27th (FEARS), 15:45-16:30</p>
                        </div>
                    </div>

                    <div className="row pbottom-default">
                        <div className="col-sm-8">
                            <h3>Workshop content</h3>
                            <p>
                                Info and Q&A session on practical aspects (funding opportunities, career perspectives…)
                                and modalities of PhD research at Ghent University faculty of engineering and
                                architecture
                            </p>
                        </div>
                        <div className="col-sm-4">
                            <h3>Location</h3>
                            <p>Calefactory, Sint-Pietersabdij<br></br>Sint-Pietersplein 9, 9000 Gent</p>
                        </div>
                    </div>

                </article>
            </div>
        </div>
    </Layout>
)

export default Workshops
